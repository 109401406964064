import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ChatbotLayout, Chatbot } from '@components';

const StyledMainContainer = styled.main`
  counter-reset: section;
`;

const IndexPage = ({ location }) => (
  <ChatbotLayout location={location}>
    <StyledMainContainer className="fillHeight">
      <Chatbot/>
    </StyledMainContainer>
      <center><iframe
        src="https://virtualassistantaichatbot-jaylyhjoewg8yfzm2pmffs.streamlit.app/?embedded=True&embed_options=dark_theme"
        width="60%"
        height="600px"
        frameBorder="0"
        display="block"
        tabindex="-1"
        allowFullScreen
      ></iframe></center>
  </ChatbotLayout>
);


IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default IndexPage;